import { createMuiTheme } from "@material-ui/core/styles";

// This allows us to wrap the entire application in our custom theme
export default createMuiTheme({
  palette: {
    primary: { main: "#06242d" },
    secondary: { main: "#209175" },
  },
  typography: {
    useNextVariants: true,
  },
});
