import React, { Suspense } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import NotFound from "./components/NotFound";
import Loading from "./components/Loading";
import theme from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";

const AlertMap = React.lazy(() => import("./pages/AlertMap"));

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/" exact component={AlertMap} />

              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
